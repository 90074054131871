import React, { Component } from 'react';
import { Collapse, Button, CardBody, Card, Container, Row, Col } from 'reactstrap';
import SideBar from '../SideBar/SideBar';
import Logo from '../../../images/logoBranco.png';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import style from './MenuMain.module.scss';


class MenuMain extends Component {

    constructor(props) {
      super(props);
      this.toggle = this.toggle.bind(this);
      this.state = { collapse: false };
    }

    toggle() {
      this.setState(state => ({ collapse: !state.collapse }));
    }

    render() {
      var arraySubPrincipal = ["Gerar Senha Provisória","Trocar Ingresso","Inserir Ingresso Manual","Criar/atualizar Clientes","Acertar nomes compradores","Contratos Pendentes (15)"];
      var arraySubPrincipal = ["Gerar Senha Provisória","Trocar Ingresso","Inserir Ingresso Manual","Criar/atualizar Clientes","Acertar nomes compradores","Contratos Pendentes (15)"];
      var arraySubPrincipal = ["Gerar Senha Provisória","Trocar Ingresso","Inserir Ingresso Manual","Criar/atualizar Clientes","Acertar nomes compradores","Contratos Pendentes (15)"];
      var arraySubPrincipal = ["Gerar Senha Provisória","Trocar Ingresso","Inserir Ingresso Manual","Criar/atualizar Clientes","Acertar nomes compradores","Contratos Pendentes (15)"];
      var arraySubPrincipal = ["Gerar Senha Provisória","Trocar Ingresso","Inserir Ingresso Manual","Criar/atualizar Clientes","Acertar nomes compradores","Contratos Pendentes (15)"];
      var arraySubPrincipal = ["Gerar Senha Provisória","Trocar Ingresso","Inserir Ingresso Manual","Criar/atualizar Clientes","Acertar nomes compradores","Contratos Pendentes (15)"];
      var arraySubPrincipal = ["Gerar Senha Provisória","Trocar Ingresso","Inserir Ingresso Manual","Criar/atualizar Clientes","Acertar nomes compradores","Contratos Pendentes (15)"];

        return (
          //<Header/>
          <Container className={style.container}>
            <Row className={style.top_bar}>
              <Col xs="2">
              <Link to="/menu"><img src={Logo} className={style.logo} alt='LogoPreto'/></Link>
              </Col>
              <Col xs="9">
                <ul className={style.menu_topBar}>
                  <li><Link to="/menu/relatorioDeVendas"><span>Relatório de Vendas (Geral)</span></Link></li>
                  <li><Link to="/menu/processosDeCompra"><span>Lista Processos de Compra</span></Link></li>
                  <li><Link to="/menu/consultarCompra"><span>Consultar Compra</span></Link></li>
                  <li><Link to="/menu/consultarUsuario"><span>Consultar Usuário</span></Link></li>
                </ul>
              </Col>
            </Row>
            <Row>
              <Col xs='2' className={style.coluna1}>

                <ul className={style.menu}>
                  <SideBar titulo="Principal" subTitulos={arraySubPrincipal}/>
                  <SideBar titulo="Ingressos" subTitulos={arraySubPrincipal}/>
                  <SideBar titulo="Financeiro" subTitulos={arraySubPrincipal}/>
                  <SideBar titulo="Gerenciamento" subTitulos={arraySubPrincipal}/>
                  <SideBar titulo="Parceiros e Produtores" subTitulos={arraySubPrincipal}/>
                  <SideBar titulo="Mailing" subTitulos={arraySubPrincipal}/>
                  <SideBar titulo="Manuais" subTitulos={arraySubPrincipal}/>
                </ul>

              </Col>
              <Col xs='10' className={style.coluna2}><div className={style.containerMenu}>{this.props.children}</div></Col>
            </Row>
          </Container>
        );
    }
}

export default MenuMain;
