import React, { Component } from 'react';
import { Row, Col, Button, Form, FormGroup, Label, Input, Modal } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import style from './ConsultaCompraBoxModal.module.scss';
import axios from 'axios';

import LoadingSpinner from '../../../../components/general/LoadingSpinner/LoadingSpinner';
import ErrorBox from '../../../../components/general/ErrorBox/ErrorBox';
import CheckMark from '../../../../images/btnCheckMark.png';
import ErroCross from '../../../../images/erroCross.png';

class ConsultaCompraBoxModal extends Component {

    constructor(props) {
      super(props);
      this.fecharModal = this.fecharModal.bind(this);
      this.selecionouMotivo = this.selecionouMotivo.bind(this);
      this.callAPI = this.callAPI.bind(this);
    }

    state = {
      showModal: false,
      motivoPlace: "Caso não esteja na lista digite o motivo aqui",
      titulo: "",
      isLoading: false,
      canClose: true,
      linkAPI: ""
    }

    componentWillReceiveProps(props) {
      const a = props.showModal
      this.setState({ showModal: a })
    }

    fecharModal() {
      if(this.state.canClose == false) return null;
      this.setState({ showModal: false, isLoading: false})
    }

    selecionouMotivo(e) {
      var motivo = e.target.value

      if(motivo == 'Selecione um motivo') {
        motivo = 'Caso não esteja na lista digite o motivo aqui'
      }

      this.setState({motivoPlace: motivo})
    }

    callAPI() {
      this.setState({isLoading: true})

      axios.get(`${this.state.linkAPI}`)
        .then(res => {
          const data = res.data;

          this.setState({
            hasError: !(data.status),
            msgRetorno: data.msg,
            isLoading: false,
            canClose: true
           });
        })

    }

    render() {

        if(this.state.showModal == false) {
          return null;
        }

        const processoDeCompra = this.props.processoDeCompra

        const {form, titulo} = this.renderSwitch(this.props.idModal)

        return (
          <Modal isOpen={true} toggle={this.fecharModal} fade={true}>
          <h3 className={style.titulo}>{titulo}</h3>
          <span className={style.fechar} onClick={this.fecharModal}>Fechar</span>
            <div className={style.modal_box}>
              <div className={style.modal_body}>

              <Form className={style.form}>
                {form}
              </Form>

              </div>
              <div className={style.modal_footer} />
            </div>
          </Modal>
        );
    }

    renderSwitch(param) {

      var isLoading = this.state.isLoading
      var processoDeCompra = this.props.processoDeCompra

      switch(param) {

        case 'MODAL_EMAIL_INVALIDO':

          const link = `/api/menu/usuario/emailInvalido/${processoDeCompra.chaveTransacao}`
          this.setState({linkAPI: link})

          var form =
          <>
          <p>Essa opção é apenas para e-mails que parecem certo (Ex: joao@hotmail.com) mas foram rejeitados pelo nosso sistema.</p>
          <p>E-mails rejeitados por spam <b className={style.atencao}>não</b> devem ser assinalados como inválidos!</p>

          <FormGroup row>
            <Label for="email" sm={2}>Email</Label>
            <Col sm={10}>
              <Input type="email" id="email" name="email" placeholder={this.props.processoDeCompra.CliEmail} disabled />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="motivo" sm={2}>Motivo</Label>
            <Col sm={10}>
              <Input type="text" id="motivo" name="motivo" placeholder="Digite o motivo" />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={12}>
              {!isLoading && <Input type="button" name="submit" id="submit" onClick={this.callAPI} value="Assinalar" className={style.submit_green}/> }
              {isLoading && <LoadingSpinner /> }
            </Col>
          </FormGroup>
          <p><b className={style.atencao}>Verifique</b> se o e-mail não possui algum erro de digitação (Ex: @hotmai.com)! Antes de assinalar inválido</p>
          </>
          var arr = Array();
          arr['titulo'] = "Assinalar E-mail inválido"
          arr['form'] = form
          return arr;

          case 'MODAL_CORRIGIR_COMPRADOR':
            var form =
            <>
            <FormGroup row>
              <Label for="exampleEmail" sm={2}>Nome</Label>
              <Col sm={10}>
                <Input type="text" name="email" id="exampleEmail" placeholder={this.props.processoDeCompra.CliNome} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="exampleEmail" sm={2}>E-mail</Label>
              <Col sm={10}>
                <Input type="text" name="email" id="exampleEmail" placeholder={this.props.processoDeCompra.CliEmail} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="exampleEmail" sm={2}>Telefone</Label>
              <Col sm={2}>
                <Input type="text" name="email" id="exampleEmail" placeholder={this.props.processoDeCompra.ddd} />
              </Col>
              <Col sm={8}>
                <Input type="text" name="email" id="exampleEmail" placeholder={this.props.processoDeCompra.telefone} />
                <p className={style.dica}>Não use símbolos</p>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="exampleEmail" sm={2}>CPF</Label>
              <Col sm={10}>
                <Input type="text" name="email" id="exampleEmail" placeholder={this.props.processoDeCompra.cpf} />
                <p className={style.dica}>Não use pontos ou traços</p>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col sm={12}>
                {!isLoading && <Input type="button" name="password" onClick={this.callAPI} id="examplePassword" value="Corrigir" className={style.submit_green}/>}
                {isLoading && <LoadingSpinner /> }
              </Col>
            </FormGroup>

            </>
            var arr = Array();
            arr['titulo'] = "Corrigir dados comprador"
            arr['form'] = form
            return arr;

            case 'MODAL_BLOQUEAR_COMPRADOR':

              var form =
              <>
              <p>Essa opção <b className={style.atencao}>irá</b> bloquear e-mail, cpf e telefone do comprador.</p>
              <FormGroup row>
                <Label for="exampleEmail" sm={2}>E-mail</Label>
                <Col sm={10}>
                  <Input type="text" name="email" id="exampleEmail" placeholder={this.props.processoDeCompra.CliEmail} disabled/>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="exampleEmail" sm={2}>Telefone</Label>
                <Col sm={2}>
                  <Input type="text" name="email" id="exampleEmail" placeholder={this.props.processoDeCompra.ddd} disabled/>
                </Col>
                <Col sm={8}>
                  <Input type="text" name="email" id="exampleEmail" placeholder={this.props.processoDeCompra.telefone} disabled/>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="exampleEmail" sm={2}>CPF</Label>
                <Col sm={10}>
                  <Input type="text" name="email" id="exampleEmail" placeholder={this.props.processoDeCompra.cpf} disabled/>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={12}>
                  {!isLoading && <Input type="button" onClick={this.callAPI} name="submit" id="submit" value="Bloquear" className={style.submit_red}/>}
                  {isLoading && <LoadingSpinner /> }
                </Col>
              </FormGroup>
              </>
              var arr = Array();
              arr['titulo'] = "Bloquear Comprador"
              arr['form'] = form
              return arr;

              case 'MODAL_CONTESTAR_COMPRA':

                var form =
                <>
                <FormGroup row>
                  <Label for="email" sm={4}>Número contestação</Label>
                  <Col sm={8}>
                    <Input type="email" id="email" name="email" placeholder="Digite o número da contestação" />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col sm={12}>
                    {!isLoading && <Input type="button" name="submit" onClick={this.callAPI} id="submit" value="Registrar" className={style.submit_red}/>}
                    {isLoading && <LoadingSpinner /> }
                  </Col>
                </FormGroup>
                </>
                var arr = Array();
                arr['titulo'] = "Registrar Contestação"
                arr['form'] = form
                return arr;

                case 'MODAL_CANCELAR_COMPRA':

                  var form =
                  <>
                  <h3 className={style.tituloAtencao}>Atenção!</h3>
                  <p>Você está prestes a cancelar essa compra. Esse processo <b className={style.atencao}>não</b> pode ser revertido</p>
                  <FormGroup row>
                    <Label for="email" sm={2}>Motivo</Label>
                    <Col sm={10}>
                      <Input type="select" onChange={this.selecionouMotivo} name="select" id="exampleSelect">
                        <option>Selecione um motivo</option>
                        <option>Desistência</option>
                        <option>Cliente não consegui assistir o espetáculo</option>
                        <option>Sessão Cancelada</option>
                        <option>Boleto compensado após a sessão</option>
                        <option>Duplicidade da compra</option>
                        <option>Hacker</option>
                      </Input>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col sm={12}>
                      <Input type="text" id="motivo" name="motivo" placeholder={this.state.motivoPlace} />
                    </Col>
                  </FormGroup>
                  <FormGroup check inline>
                    <Label check>
                      <Input type="radio" value={true} name="cancelarRadio" id="cancelarRadio" />Cancelar na Operadora
                    </Label>
                  </FormGroup>
                  <FormGroup check inline>
                    <Label check>
                       <Input type="radio" value={false} name="cancelarRadio" id="cancelarRadio"/>Não Cancelar
                    </Label>
                  </FormGroup>
                  <FormGroup row>
                    <Col sm={12}>
                    <span className={style.dica}>Não é possível cancelar na operadora no fim de semana.</span>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col sm={12}>
                      {!isLoading && <Input type="button" name="submit" onClick={this.callAPI} id="submit" value="Cancelar" className={style.submit_red}/>}
                      {isLoading && <LoadingSpinner /> }
                    </Col>
                  </FormGroup>
                  </>
                  var arr = Array();
                  arr['titulo'] = "Cancelar compra"
                  arr['form'] = form
                  return arr;


        default:
          return null;
      }
    }
}

export default ConsultaCompraBoxModal;
