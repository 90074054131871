import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from '../../../images/logoBranco.png';

import MenuItem from '../MenuItem/MenuItem';

import IconeAdulto from '../../../images/teatroadulto_branco.png';
import IconeStandUp from '../../../images/icone_standUp.png';
import IconeInfantil from '../../../images/infantil_branco.png';
import IconeShow from '../../../images/icon_shows.png';
import IconePasseio from '../../../images/passeios_branco.png';
import IconeLocal from '../../../images/teatro_branco.png';
import IconeVoucher from '../../../images/voucher_branco.png';
import IconeUsuario from '../../../images/usuario_branco.png';

import './index.scss';

class Header extends Component {
    render() {
        return (
          <Container className="container-fluid">
            <Row className="header">
              <ul className='header-options'>
                <li><img src={Logo} alt="Logo"/></li>
                <li>
                  <div>
                    <ul className='header-options-menu'>
                      <li>
                        <input type="text" class="busca" name="search" id="busca_field" placeholder="Buscar..." />
                        <hr />
                      </li>
                      <li>
                        <select name="genero">
                          <option value="">Gênero?</option>
                        </select>
                        <hr />
                      </li>
                      <li>
                        <select name="onde">
                          <option value="">Onde?</option>
                        </select>
                        <hr />
                      </li>
                      <li>
                        <select name="quando" value="">
                          <option value="">Quando?</option>
                        </select>
                        <hr/>
                      </li>
                      <li>
                        <select name="quanto">
                              <option value="">Quanto?</option>
                        </select>
                      </li>
                      <li><input type="button" value="Buscar" className='btn_buscar' /></li>
                    </ul>
                  </div>
                </li>
                <li>
                  <MenuItem titulo='Minha Conta' image={IconeUsuario} link="minhaconta" className="minhaContaHeader"/>
                </li>
              </ul>
            </Row>
            <Row className='menu_header'>
              <div className="menu-options">
                <MenuItem titulo='Teatro Adulto' image={IconeAdulto} link="/espetaculos/adulto" />
                <MenuItem titulo='Stand Up' image={IconeStandUp} link="/espetaculos/standUp"/>
                <MenuItem titulo='Infantil' image={IconeInfantil} link="/espetaculos/infantil" />
                <MenuItem titulo='Shows' image={IconeShow} link="/espetaculos/shows" />
                <MenuItem titulo='Passeios' image={IconePasseio} link="/espetaculos/passeios" />
                <MenuItem titulo='Locais' image={IconeLocal} link="/locais" />
                <MenuItem titulo='Meu Voucher' image={IconeVoucher} link="/meuvoucher" />
                <MenuItem titulo='Minha Conta' image={IconeUsuario}  link="/minhaconta" className="minhaContaMenu"/>
              </div>
            </Row>
          </Container>
        );
    }
}

export default Header;
