import React, { Component } from 'react';
import { Col } from 'reactstrap';
import { Link } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';

class MenuItem extends Component {
    render() {
        return (
          <Col className={this.props.className}>
              <ul className="iconeTeatro">
                <Link to={this.props.link}>

                <li><img src={this.props.image} /></li>
                <li>{this.props.titulo}</li>
                </Link>

              </ul>
          </Col>
        );
    }
}

export default MenuItem;
