import React, { Component } from 'react';
import MainBody from '../MainBody/MainBody';

import EspetaculoPrincipalBox from '../../../components/website/EspetaculoDetalhes/EspetaculoPrincipalBox/EspetaculoPrincipalBox';


import style from './EspetaculoDetalhes.module.scss';

import { Container, Row, Col } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import Foto from '../../../images/foto_83505.jpg';

import Avaliacao1 from '../../../images/aval1_branco.png';
import Avaliacao2 from '../../../images/aval2_branco.png';
import Avaliacao3 from '../../../images/aval3_branco.png';
import Avaliacao4 from '../../../images/aval4_branco.png';

import PrecoSampa from '../../../images/precoSampa.png';
import PrecoBilheteria from '../../../images/precoBilheteria.png';

import ShareFacebook from '../../../images/share_facebook.png';
import ShareTwitter from '../../../images/share_twitter.png';

import RelogioPreto from '../../../images/relogioPreto.png';
import GeneroIcon from '../../../images/generoIcon.png';
import Classificao10Anos from '../../../images/10anos.png';


class EspetaculoDetalhes extends Component {

  constructor(props) {
    super(props);
    this.handleMenu = this.handleMenu.bind(this);
  }

    state = {
      menuSelecionado: 0
    }

handleMenu(e) {
    const idMenu = e.currentTarget.dataset.id;
    this.setState({ menuSelecionado: idMenu });
}


  componentDidMount(){
    document.title = "Sampa Ingressos - Espetáculos adulto"
  }

    render() {
        const state = this.state;
        const espetaculo = this.props.espetaculo;
        const imagemUrl = "https://sampaingressos.com.br/" + espetaculo.imagem;
        const imagemRecomendacao = "/images/" + espetaculo.imagemRecomendacaoEtaria;

        return (
          <MainBody>

            <Row className={style.container}>

            <Col md={5} lg={5}>
              <div className={style.imagemEspetaculo} style={{
                backgroundImage: `url(${imagemUrl})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
              }} />
              <ul className={style.espetaculoInfoBox}>
                <li>
                  <p><img src={GeneroIcon} />{espetaculo.genero}</p>
                </li>
                <li>
                  <p><img src={RelogioPreto} className={style.relogio} />{espetaculo.duracao}</p>
                </li>
                <li>
                  <p><img src={imagemRecomendacao} />{espetaculo.recomendacaoEtaria}</p>
                </li>
              </ul>
            </Col>

            <Col md={6} lg={7} className={style.box} >
              <div className={style.titulo} >
                <div className={style.desconto}><span>{espetaculo.desconto}</span></div>
                <h3>{espetaculo.nome}</h3>
                <ul className={style.ul_aval}>
                  <li><img src={Avaliacao4} />{espetaculo.qtdRecomendacoes4}</li>
                  <li><img src={Avaliacao3} />{espetaculo.qtdRecomendacoes3}</li>
                  <li><img src={Avaliacao2} />{espetaculo.qtdRecomendacoes2}</li>
                  <li><img src={Avaliacao1} />{espetaculo.qtdRecomendacoes1}</li>
                </ul>
              </div>

              <ul className={style.ul_menu}>
                <li onClick={this.handleMenu} data-id="0" className={state.menuSelecionado != 0 && style.unchosen}><p>Principal</p></li>
                <li onClick={this.handleMenu} data-id="1" className={state.menuSelecionado != 1 && style.unchosen}><p>Local</p></li>
                <li onClick={this.handleMenu} data-id="2" className={state.menuSelecionado != 2 && style.unchosen}><p>Comentários</p></li>
              </ul>

              {state.menuSelecionado == 0 && <EspetaculoPrincipalBox sinopse={espetaculo.sinopse} horario={espetaculo.horario} temporada={espetaculo.temporada} direcao={espetaculo.diretor_elenco} /> }
              {state.menuSelecionado == 1 && <EspetaculoPrincipalBox /> }
              {state.menuSelecionado == 2 && "ola!" }

              <hr />
              <div>

                <Row>
                  <Col md={12} xl={8}>
                    <ul className={style.ul_valorEspetaculo}>
                      <li>
                        <img src={PrecoBilheteria} />
                        <del>{espetaculo.valorIngressos}</del>
                      </li>
                      <li>
                        <img src={PrecoSampa} className={style.imgSampa} />
                        <p>{espetaculo.valorSampaIngressos}</p>
                      </li>
                    </ul>
                  </Col>
                  <Col md={12} xl={4}>
                    <p className={style.comprar}>Compre</p>
                  </Col>
                </Row>


              </div>

            </Col>
            <Col xs={12} className={style.center}>
              <div className={style.redeSociais}>
                <p>Compartilhe</p>
                <ul className={style.ul_redes}>
                  <li><img src={ShareFacebook} /></li>
                  <li><img src={ShareTwitter} /></li>
                </ul>
              </div>
            </Col>

            </Row>

          </MainBody>
        );
    }
}

export default EspetaculoDetalhes;
