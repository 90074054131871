import React, { Component } from 'react';
import { Collapse } from 'reactstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';


class SideBar extends Component {

    constructor(props) {
      super(props);
      this.toggle = this.toggle.bind(this);
      this.state = { collapse: false };
    }

    toggle() {
      this.setState(state => ({ collapse: !state.collapse }));
    }

    render() {
        const subTitulos = this.props.subTitulos.map(item => <li>{item}<hr/></li> );

        return (
          <li onClick={this.toggle}>
            <p>{this.props.titulo}</p>


            <Collapse isOpen={this.state.collapse}>
              <ul className="subTitulos_ul">
              {subTitulos}
              </ul>
            </Collapse>
            <hr />
          </li>
        );
    }
}

export default SideBar;
