import React, { Component } from 'react';
import { Row, Container, Col } from 'reactstrap';
import Logo from '../../../images/logoBranco.png';
import { Link } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import style from './LoginFuncionario.module.scss';

import ErroCross from '../../../images/erroCross.png';

class LoginFuncionario extends Component {

    render() {



        return (
          <Container className={style.container}>
            <Row className={style.top_bar}>
              <img src={Logo} className={style.logo} alt='LogoPreto'/>
            </Row>

            <div className={style.box}>
              <img src={ErroCross} />
              <p>Você não está logado!</p>

              <Link to="/menu"><span>Clique aqui caso tenha se logado</span></Link>

            </div>

          </Container>
        );
    }
}

export default LoginFuncionario;
