import React, { Component } from 'react';
import MainBody from '../MainBody/MainBody';
// import Footer from '../../components/Footer/Footer';
import BoxEspetaculo from '../../../components/website/BoxEspetaculo/BoxEspetaculo';
import style from './LandingPage.module.scss';
import axios from 'axios';

import { Container, Row, Col } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import Destaques from '../Destaques/Destaques';
import EspetaculoDetalhes from '../EspetaculoDetalhes/EspetaculoDetalhes';


class LandingPage extends Component {

  constructor(props) {
    super(props);
  }

  state = {
    isLoading: false,
    espetaculo: [],
    msg: "",
    hasError: false
  }

  componentDidMount() {
    const urlEspetaculo = this.props.match.params["urlEspetaculo"];

    axios.get(`/api/espetaculo/` + urlEspetaculo)
      .then(res => {
        const data = res.data;
        this.setState({
          isLoading: false,
          hasError: !(data.status),
          msg : data.msg,
          espetaculo : data.retorno
      });
    });
  }

  render() {

    const urlEspetaculo = this.props.match.params["urlEspetaculo"];

    var ehEspetaculo = false;
    var ehDestaqueEspetaculo = true;

    if(urlEspetaculo != "")
    {
      ehEspetaculo = true;
    }
    if(urlEspetaculo == "menu" || urlEspetaculo == "locais" || urlEspetaculo == "produtor" ) {
      ehDestaqueEspetaculo = false;
    }

    console.log(this.state);

    return (
      <>
      { !urlEspetaculo && ehDestaqueEspetaculo && <Destaques /> }
      { urlEspetaculo && ehDestaqueEspetaculo && <EspetaculoDetalhes espetaculo={this.state.espetaculo}/> }
      </>
    );
  }
}

export default LandingPage;
