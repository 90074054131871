import React, { Component } from 'react';
import style from './ErrorBox.module.scss';

import ErrorCross from '../../../images/erroCross.png';

class ErrorBox extends Component {

  constructor(props) {
    super(props);
  }

    render() {

        return (
        <div className={style.box}>
          <img src={ErrorCross} alt='Error' />
          <p>Error!</p>
          <hr />
          <span>{this.props.msg}</span>
        </div>
        );
    }
}

export default ErrorBox;
