import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import style from './EspetaculoPrincipalBox.module.scss';

import Calendario from '../../../../images/calendario.png';
import Direcao from '../../../../images/direcao_vermelho.png';
import LerMais from '../../../../images/ler_mais.png';
import LerMenos from '../../../../images/ler_menos.png';

class EspetaculoPrincipalBox extends Component {

    constructor(props) {
      super(props);
    }

    render() {

      const props = this.props;
      return (
        <div className={style.info} >
          <div className={style.sinopseBox}>
            {props.sinopse}
            <div className={style.lerMais} >
              <img src={LerMais} />
            </div>
          </div>
        <hr />
        <ul className={style.direcaoTemporada}>

          <li>
            <div className={style.tituloDirecaoTemporada}>
              <img src={Calendario} />
              <p>Temporada</p>
              <span className={style.temporada}>{props.temporada}</span>
              <span className={style.temporada}>{props.horario}</span>
            </div>
          </li>

          <li>
            <div className={style.tituloDirecaoTemporada}>
              <img src={Direcao} />
              <p>Direção / Elenco</p>
              <span>{props.diretor_elenco}</span>
            </div>
          </li>

        </ul>
        </div>
      )
    }
}

export default EspetaculoPrincipalBox;
