import React, { Component } from 'react';
import { Table } from 'reactstrap';
import { Col, Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';

import RelatorioDeVendasCelula from '../RelatorioDeVendasCelula/RelatorioDeVendasCelula';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';

class RelatorioDeVendasTabela extends Component {

    constructor(props) {
        super(props)
    }
    render() {

      if(!this.props.relatorio) { return null; }

      const arrEspetaculos = this.props.relatorio.map(sessao =>
        <RelatorioDeVendasCelula ingressos={sessao["ingressos"]} dataSessao={sessao["dataSessao"]} />
       );

      return (
        <Table>
          <thead>
            <tr>
              <th>Chave</th>
              <th>Cliente</th>
              <th>Sessão</th>
              <th>Quantidade Ingressos</th>
              <th>Valor Unitário (R$)</th>
              <th>Valor da compra</th>
              <th>Valor do crédito</th>
              <th>Comissão</th>
              <th>Valor Produtor</th>
            </tr>
          </thead>
          <tbody>
            {arrEspetaculos}
          </tbody>
        </Table>
    );
  }
}

export default RelatorioDeVendasTabela;
