import React, { Component } from 'react';
import MainBody from '../MainBody/MainBody';
// import Footer from '../../components/Footer/Footer';
import BoxEspetaculo from '../../../components/website/BoxEspetaculo/BoxEspetaculo';
import style from './ListaEspetaculos.module.scss';

import { Container, Row, Col } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import SugestionComedia from '../../../images/sugestionComedia.png';
import SugestionDrama from '../../../images/sugestionDrama.png';
import SugestionHoje from '../../../images/sugestionHoje.png';
import SugestionMusical from '../../../images/sugestionMusical.png';


class ListaEspetaculos extends Component {

  componentDidMount(){
    document.title = "Sampa Ingressos - Espetáculos adulto"
  }

    render() {
        return (
          <MainBody>

          <div className={style.titulo} >
            <h3>Teatro Adulto</h3>
            <p>Espetáculos teatrais e musicais no estado de São Paulo</p>
            <hr />
          </div>

          <Row className={style.categorias} >

          <Col xs={6} md={3}>
            <a href='#'>
              <img src={SugestionHoje} />
            </a>
          </Col>
          <Col xs={6} md={3}>
            <a href='#'>
              <img src={SugestionComedia} />
            </a>
          </Col>
          <Col xs={6} md={3} >
            <a href='#'>
              <img src={SugestionDrama} />
            </a>
          </Col>
          <Col xs={6} md={3} >
            <a href='#'>
              <img src={SugestionMusical} />
            </a>
          </Col>

          </Row>

          <Row className={style.boxEspetaculos}>
            <Col xs={12} sm={6} md={4} lg={3} className={style.espetaculo}><BoxEspetaculo /></Col>
            <Col xs={12} sm={6} md={4} lg={3} className={style.espetaculo}><BoxEspetaculo /></Col>
            <Col xs={12} sm={6} md={4} lg={3} className={style.espetaculo}><BoxEspetaculo /></Col>
            <Col xs={12} sm={6} md={4} lg={3} className={style.espetaculo}><BoxEspetaculo /></Col>
            <Col xs={12} sm={6} md={4} lg={3} className={style.espetaculo}><BoxEspetaculo /></Col>
            <Col xs={12} sm={6} md={4} lg={3} className={style.espetaculo}><BoxEspetaculo /></Col>
            <Col xs={12} sm={6} md={4} lg={3} className={style.espetaculo}><BoxEspetaculo /></Col>
            <Col xs={12} sm={6} md={4} lg={3} className={style.espetaculo}><BoxEspetaculo /></Col>
            <Col xs={12} sm={6} md={4} lg={3} className={style.espetaculo}><BoxEspetaculo /></Col>
            <Col xs={12} sm={6} md={4} lg={3} className={style.espetaculo}><BoxEspetaculo /></Col>
            <Col xs={12} sm={6} md={4} lg={3} className={style.espetaculo}><BoxEspetaculo /></Col>
            <Col xs={12} sm={6} md={4} lg={3} className={style.espetaculo}><BoxEspetaculo /></Col>
          </Row>

          </MainBody>
        );
    }
}

export default ListaEspetaculos;
