import React, { Component } from 'react';
import style from './LoadingSpinner.module.scss';

import LoadingSpinnerIco from '../../../images/LoadingSpinner.svg';

class LoadingSpinner extends Component {

  constructor(props) {
    super(props);
  }

    render() {

        return (
        <div className={style.box}>
          <img src={LoadingSpinnerIco} alt='Loading...' />
        </div>
        );
    }
}

export default LoadingSpinner;
