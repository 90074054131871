import React, { Component } from 'react';
import { Row, Container, Col } from 'reactstrap';
import Logo from '../../../images/produSampa_branco.png';
import { Link } from 'react-router-dom';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import style from './MenuProdutor.module.scss';

import ErroCross from '../../../images/erroCross.png';

import MenuMainProdutor from '../../../components/produtor/MenuMainProdutor/MenuMainProdutor';



class MenuProdutor extends Component {

    render() {

      document.body.style = 'background: #979797;';

        return (
          <MenuMainProdutor>
          "oi"

          </MenuMainProdutor>
        );
    }
}

export default MenuProdutor;
