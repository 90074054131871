import React, { Component } from 'react';
import { Table } from 'reactstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';

import CheckMark from '../../../images/btnCheckMark.png';

class ProcessoDeCompra extends Component {

  constructor(props) {
      super(props)
  }

      state = {
        compraAprovada: "",
        nivelAtencao: 0
      }


    componentDidMount() {
      if(this.props.processoDeCompra.aceitaVoucherManual == 1)

        this.setState({compraAprovada: "compraAprovada"});
      }

    

    render() {

        return (
          <tr className={this.state.compraAprovada}>
            <td><b>{this.props.processoDeCompra.identificador}</b></td>
            <td>{this.props.processoDeCompra.dataHora}</td>
            <td>{this.props.processoDeCompra.nomeEspetaculo}</td>
            <td><img className="checkMark" src={CheckMark} /></td>
            <td><span className="dataSessao">{this.props.processoDeCompra.dataSessao} {this.props.processoDeCompra.horaSessao}h</span></td>
            <td>{this.props.processoDeCompra.qtdIngressosFormatada}</td>
            <td>{this.props.processoDeCompra.CliNome}</td>
            <td>{this.props.processoDeCompra.CliEmail}</td>
            <td>{this.props.processoDeCompra.cpf}</td>
            <td>{this.props.processoDeCompra.telefone}</td>
          </tr>
        );
    }
}

export default ProcessoDeCompra;
