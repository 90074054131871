import React, { Component } from 'react';
import MainBody from '../MainBody/MainBody';
// import Footer from '../../components/Footer/Footer';
import BoxEspetaculo from '../../../components/website/BoxEspetaculo/BoxEspetaculo';
import style from './Destaques.module.scss';

import DestaqueSemana from '../../../images/destaqueSemana.png';
import MelhoresAvaliados from '../../../images/melhoresAvaliados.png';
import MelhoresDescontos from '../../../images/melhoresDescontos.png';
import ProximasEstreias from '../../../images/proximasEstreias.png';
import Classicos from '../../../images/classicos.png';


import SuperBanner from '../../../images/superBanner.jpg';
import Banner from '../../../images/banner.jpg';



class Destaques extends Component {

  componentDidMount(){
    document.title = "Sampa Ingressos - Destaques"
  }

    render() {
        return (
          <MainBody>

          <div className={style.superBanner}>
            <img src={SuperBanner} />
            <a href='#' />
          </div>

          <img src={DestaqueSemana} className= {style.sectionPictureHighlight} />

          <div class={style.boxEspetaculos}>
          	<div class={style.espetaculo}><BoxEspetaculo /></div>
          	<div class={style.espetaculo}><BoxEspetaculo /></div>
          	<div class={style.espetaculo}><BoxEspetaculo /></div>
          	<div class={style.espetaculo}><BoxEspetaculo /></div>
          </div>

          <div className={style.banner}>
            <img src={Banner} />
            <a href='#' />
          </div>

          <div className={style.sectionPicture}>
            <hr />
            <div><img src={MelhoresAvaliados} /></div>
            <a href='#'>Ver Mais</a>
          </div>

          <div class={style.boxEspetaculos}>
            <div class={style.espetaculo}><BoxEspetaculo /></div>
            <div class={style.espetaculo}><BoxEspetaculo /></div>
            <div class={style.espetaculo}><BoxEspetaculo /></div>
            <div class={style.espetaculo}><BoxEspetaculo /></div>
          </div>

          <div className={style.sectionPicture}>
            <hr />
            <div><img src={ProximasEstreias} /></div>
            <a href='#'>Ver Mais</a>
          </div>

          <div class={style.boxEspetaculos}>
            <div class={style.espetaculo}><BoxEspetaculo /></div>
            <div class={style.espetaculo}><BoxEspetaculo /></div>
            <div class={style.espetaculo}><BoxEspetaculo /></div>
            <div class={style.espetaculo}><BoxEspetaculo /></div>
          </div>

          <div className={style.banner}>
            <img src={Banner} />
            <a href='#' />
          </div>

          <div className={style.sectionPicture}>
            <hr />
            <div><img src={Classicos} /></div>
          </div>

          <div class={style.boxEspetaculos}>
            <div class={style.espetaculo}><BoxEspetaculo /></div>
            <div class={style.espetaculo}><BoxEspetaculo /></div>
            <div class={style.espetaculo}><BoxEspetaculo /></div>
            <div class={style.espetaculo}><BoxEspetaculo /></div>
          </div>

          <div className={style.sectionPicture}>
            <hr />
            <div><img src={MelhoresDescontos} /></div>
            <a href='#'>Ver Mais</a>
          </div>

          <div class={style.boxEspetaculos}>
            <div class={style.espetaculo}><BoxEspetaculo /></div>
            <div class={style.espetaculo}><BoxEspetaculo /></div>
            <div class={style.espetaculo}><BoxEspetaculo /></div>
            <div class={style.espetaculo}><BoxEspetaculo /></div>
          </div>

          </MainBody>
        );
    }
}

export default Destaques;
