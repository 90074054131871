import React, { Component } from 'react';
import { Table } from 'reactstrap';
import axios from 'axios';
import { Col, Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';

import MenuMain from '../../../components/menu/MenuMain/MenuMain';
import RelatorioDeVendasTabela from '../../../components/menu/RelatorioDeVendasTabela/RelatorioDeVendasTabela';
import LoadingSpinner from '../../../components/general/LoadingSpinner/LoadingSpinner';
import ErrorBox from '../../../components/general/ErrorBox/ErrorBox';


import 'bootstrap/dist/css/bootstrap.min.css';
import style from './RelatorioDeVendas.module.scss';

import CheckMark from '../../../images/btnCheckMark.png';

class RelatorioDeVendas extends Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

    state = {
      isLoading: false,
      espetaculos: [],
      relatorio: [],
      msg: "",
      hasError: false
    }

    componentDidMount() {
      axios.get(`/api/menu/relatorioDeVendas`)
        .then(res => {
          const espetaculos = res.data;
          this.setState({ espetaculos: espetaculos });
        })
    }

    handleChange(event) {
      event.preventDefault();
      this.setState({
        isLoading: true,
        relatorio: []
      });
      const form = document.getElementById("formulario");
      const formData = new FormData(form);
      axios.post(`/api/menu/relatorioDeVendas/detalhes`, formData)
        .then(res => {
          const data = res.data;
          this.setState({
            isLoading: false,
            hasError: !(data.status),
            msg : data.msg,
            relatorio : JSON.parse(data.retorno)
          });
        })
    }

    render() {

      const arrEspetaculos = this.state.espetaculos.map(espetaculo =>
        <option value={espetaculo.idEspetaculo}>{espetaculo.nome} - {espetaculo.local}</option>
       );

        return (
          <MenuMain>
            <div className={style.topBar}>
              <h3>Relatório de Vendas</h3>
              <hr />
              <Form id="formulario">
                <FormGroup row>
                  <Label for="idEspetaculo" sm={3}>Selecione o espetáculo:</Label>
                  <Col sm={8}>
                  <Input type="select" name="idEspetaculo" id="idEspetaculo" onChange={this.handleChange}>
                    <option>Selecione um espetáculo</option>
                    {arrEspetaculos}
                  </Input>
                  </Col>
                </FormGroup>
              </Form>
            </div>
              { this.state.relatorio && <RelatorioDeVendasTabela relatorio={this.state.relatorio} />}
              { this.state.isLoading && <LoadingSpinner />}
              { this.state.hasError && <ErrorBox msg={this.state.msg} />}
          </MenuMain>
        );
    }
}

export default RelatorioDeVendas;
