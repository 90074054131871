import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';

//imports website
import LandingPage from './screens/website/LandingPage/LandingPage';
import Destaques from './screens/website/Destaques/Destaques';
import ListaEspetaculos from './screens/website/ListaEspetaculos/ListaEspetaculos';

//imports produtor
import LoginProdutor from './screens/produtor/LoginProdutor/LoginProdutor';
import MenuProdutor from './screens/produtor/MenuProdutor/MenuProdutor';

//imports Menu
import ListaProcessosDeCompra from './screens/menu/ListaProcessosDeCompra/ListaProcessosDeCompra';
import RelatorioDeVendas from './screens/menu/RelatorioDeVendas/RelatorioDeVendas';
import ConsultaCompra from './screens/menu/ConsultaCompra/ConsultaCompra';
import LoginFuncionario from './screens/menu/LoginFuncionario/LoginFuncionario';

import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'

const fakeAuth = {
  isAuthenticated: true,
  authenticate(cb) {
    this.isAuthenticated = true
    setTimeout(cb, 100)
  },
  signout(cb) {
    this.isAuthenticated = false
    setTimeout(cb, 100)
  }
}


const PrivateRouteProdutor = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    fakeAuth.isAuthenticated === true
      ? <Component {...props} />
      : <Redirect to='/produtor' />
  )} />
)

const PrivateRouteUsuario = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    fakeAuth.isAuthenticated === true
      ? <Component {...props} />
      : <Redirect to='/login' />
  )} />
)

const PrivateRouteFuncionario = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    fakeAuth.isAuthenticated === true
      ? <Component {...props} />
      : <Redirect to='/menu/login' />
  )} />
)


ReactDOM.render(


  <BrowserRouter>


    <Route path="/:urlEspetaculo?" component={LandingPage} exact/>
    <Route path="/evento/:urlEspetaculo?" component={LandingPage} exact/>
    <Route path="/espetaculos/:tipoEspetaculo" component={ListaEspetaculos} exact/>

    <Route
      path="/produtor"
      render={({ match: { url } }) => (
        <>
          {!fakeAuth.isAuthenticated && <Route path={`${url}`} component={LoginProdutor} exact /> }
          <PrivateRouteProdutor path={`${url}`} component={MenuProdutor} exact />
          <PrivateRouteProdutor path={`${url}/consultarCompra/:handle?`} component={ConsultaCompra} exact />
          <PrivateRouteProdutor path={`${url}/relatorioDeVendas`} component={RelatorioDeVendas} exact />
          <PrivateRouteProdutor path={`${url}/processosDeCompra`} component={ListaProcessosDeCompra} exact />
        </>
      )}
    />


    <Route
      path="/menu"
      render={({ match: { url } }) => (
        <>
          { fakeAuth.isAuthenticated && <Route path={`${url}/login`} component={LoginFuncionario} exact /> }
          <PrivateRouteFuncionario path={`${url}`} component={ListaProcessosDeCompra} exact />
          <PrivateRouteFuncionario path={`${url}/processosDeCompra`} component={ListaProcessosDeCompra} exact />
          <PrivateRouteFuncionario path={`${url}/consultarCompra/:handle?`} component={ConsultaCompra} exact />
          <PrivateRouteFuncionario path={`${url}/relatorioDeVendas`} component={RelatorioDeVendas} exact />
          <PrivateRouteFuncionario path={`${url}/processosDeCompra`} component={ListaProcessosDeCompra} exact />
        </>
      )}
    />




  </BrowserRouter>

    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
