import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from '../../../components/website/Header/Header';
import Footer from '../../../components/website/Footer/Footer';
import './MainBody.module.scss';

class MainBody extends Component {

  constructor(props) {
    super(props);
  }

  render() {
      return (
      <div>
        <Header />
        {this.props.children}
        <Footer />
      </div>
      );
  }
}

export default MainBody;
