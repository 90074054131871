import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import style from './RelatorioDeVendasCelula.module.scss';

class RelatorioDeVendasCelula extends Component {

    constructor(props) {
        super(props)
    }

    render() {

      if(!this.props.ingressos) return null;
      if(!this.props.ingressos.ingressos) return (
        <React.Fragment>
          <tr><td colspan='9' className={style.data}>{this.props.dataSessao}</td></tr>
          <tr><td colspan='9' className={style.semVendas}>Sem Vendas</td></tr>
        </React.Fragment>
      );

      const ingressos = this.props.ingressos.ingressos.map(ingresso =>
        <tr>
          <td>{ingresso.chaveTransacao}</td>
          <td>{ingresso.nomeCliente}</td>
          <td>{ingresso.horaSessao}</td>
          <td>{ingresso.qtdIngressos}</td>
          <td>{ingresso.valorUnitario}</td>
          <td>{ingresso.valorTotal}</td>
          <td>{ingresso.valorCredito}</td>
          <td>{ingresso.taxasSampa}</td>
          <td>{ingresso.valorAreceber}</td>
        </tr>
       );

        return (
          <React.Fragment>
            <tr>
              <td colspan='9' className={style.data}>{this.props.dataSessao}</td>
            </tr>
            {ingressos}
          </React.Fragment>
        );
    }
}

export default RelatorioDeVendasCelula;
