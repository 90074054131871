import React, { Component } from 'react';
import { Table } from 'reactstrap';
import axios from 'axios';
import { Col, Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';

import MenuMain from '../../../components/menu/MenuMain/MenuMain';

import 'bootstrap/dist/css/bootstrap.min.css';
import style from './ListaProcessosDeCompra.module.scss';

import CheckMark from '../../../images/btnCheckMark.png';

import ProcessoDeCompra from '../../../components/menu/ProcessoDeCompra/ProcessoDeCompra';


class ListaProcessosDeCompra extends Component {

    state = {
      compraAprovada: false,
      processosDeCompra: [
  {
    "CliNome": "Armando Figueiró Pinheiro",
    "CliEmail": "armandopinheiro@live.com",
    "telefone": "(11) 9 7289-1454",
    "cpf": "021.487.598-92",
    "identificador": 3078830,
    "dataHora": "18:42",
    "nomeEspetaculo": "Crime e Castigo",
    "compraAprovada": false,
    "aceitaVoucherManual": 1,
    "dataSessao": "28/07",
    "horaSessao": "20:30"
  },
  {
    "CliNome": "PAULA FERNANDA DA SILVA",
    "CliEmail": "19paulafernanda@gmail.com",
    "telefone": "(11) 9 8549-2436",
    "cpf": "303.262.098-83",
    "identificador": "SL3I8I",
    "dataHora": "18:28",
    "nomeEspetaculo": "Emerson Ceará - Trago Verdades",
    "compraAprovada": true,
    "aceitaVoucherManual": 1,
    "dataSessao": "15/08",
    "horaSessao": "21:30"
  },
  {
    "CliNome": "FRANCISCO RAFAEL PEREIRA VERONEZE",
    "CliEmail": "franciscorpveroneze@gmail.com",
    "telefone": "(19) 9 9841-5154",
    "cpf": "387.171.308-24",
    "identificador": 3078825,
    "dataHora": "18:25",
    "nomeEspetaculo": "Circo da Patrulha",
    "compraAprovada": false,
    "aceitaVoucherManual": 1,
    "dataSessao": "10/08",
    "horaSessao": "15:00"
  },
  {
    "CliNome": "LORRAYNE Galdino",
    "CliEmail": "lorrayne.galdino@hotmail.com",
    "telefone": "(11) 9 5656-3704",
    "cpf": "470.060.988-58",
    "identificador": "84XGQQ",
    "dataHora": "18:16",
    "nomeEspetaculo": "Casa, depois me conta...",
    "compraAprovada": true,
    "aceitaVoucherManual": 1,
    "dataSessao": "28/07",
    "horaSessao": "19:30"
  },
  {
    "CliNome": "Luiz Ricardo Sophia dorado ",
    "CliEmail": "ligiavcarvalho@gmail.com",
    "telefone": "(65) 9 9907-2667",
    "cpf": "024.938.441-81",
    "identificador": "AX3XFJ",
    "dataHora": "18:12",
    "nomeEspetaculo": "Trair e Coçar é Só Começar",
    "compraAprovada": true,
    "aceitaVoucherManual": 1,
    "dataSessao": "28/07",
    "horaSessao": "20:00"
  },
  {
    "CliNome": "MARCOS ALBERTO DE MORAES MARQUES HENRIQUES",
    "CliEmail": "marquinhosdemoraescompositor@gmail.com",
    "telefone": "(21) 9 8014-1188",
    "cpf": "146.317.277-05",
    "identificador": "DL6H8Q",
    "dataHora": "18:11",
    "nomeEspetaculo": "E Aí, Sumida? Fred Elboni",
    "compraAprovada": true,
    "aceitaVoucherManual": 1,
    "dataSessao": "28/07",
    "horaSessao": "20:00"
  },
  {
    "CliNome": "Ariane de Melo Ribeiro ",
    "CliEmail": "arianeribeiro123@hotmail.com",
    "telefone": "(11) 9 7466-0586",
    "cpf": "452.097.518-21",
    "identificador": 3078806,
    "dataHora": "18:07",
    "nomeEspetaculo": "A Banheira",
    "compraAprovada": false,
    "aceitaVoucherManual": 1,
    "dataSessao": "03/08",
    "horaSessao": "21:30"
  },
  {
    "CliNome": "Kenia Ribas",
    "CliEmail": "keniaribas13@gmail.com",
    "telefone": "(11) 9 8548-4915",
    "cpf": "361.058.508-08",
    "identificador": "17QE9E",
    "dataHora": "18:05",
    "nomeEspetaculo": "E Aí, Sumida? Fred Elboni",
    "compraAprovada": true,
    "aceitaVoucherManual": 1,
    "dataSessao": "28/07",
    "horaSessao": "20:00"
  },
  {
    "CliNome": "Mariana Oliveira Freitas ",
    "CliEmail": "marianafreitas.mof@gmail.com",
    "telefone": "(11) 9 5847-2174",
    "cpf": "337.826.328-85",
    "identificador": "NQ9AGV",
    "dataHora": "18:04",
    "nomeEspetaculo": "Aliados do Stand Up",
    "compraAprovada": true,
    "aceitaVoucherManual": 1,
    "dataSessao": "30/07",
    "horaSessao": "21:30"
  },
  {
    "CliNome": "Ricardo Alexandre dos santos",
    "CliEmail": "tecras.adm@gmail.com",
    "telefone": "(11) 9 8454-1225",
    "cpf": "263.196.188-92",
    "identificador": "8FXGPE",
    "dataHora": "18:02",
    "nomeEspetaculo": "Trair e Coçar é Só Começar",
    "compraAprovada": true,
    "aceitaVoucherManual": 1,
    "dataSessao": "28/07",
    "horaSessao": "20:00"
  },
  {
    "CliNome": "Rafael Yonamine",
    "CliEmail": "rafael.oliveiray@gmail.com",
    "telefone": "(11) 9 9985-1367",
    "cpf": "338.807.408-95",
    "identificador": "SQN53H",
    "dataHora": "17:50",
    "nomeEspetaculo": "Quando Tudo Estiver Pronto",
    "compraAprovada": true,
    "aceitaVoucherManual": 1,
    "dataSessao": "28/07",
    "horaSessao": "20:00"
  },
  {
    "CliNome": "Luiz Gustavo Gianesi ",
    "CliEmail": "luizgustavogianesi@uol.com.br",
    "telefone": "(11) 9 9274-5399",
    "cpf": "259.190.808-76",
    "identificador": "5FQVB2",
    "dataHora": "17:48",
    "nomeEspetaculo": "Chico - Além da Alegria",
    "compraAprovada": true,
    "aceitaVoucherManual": 1,
    "dataSessao": "28/07",
    "horaSessao": "19:30"
  },
  {
    "CliNome": "Bernardo Gradin",
    "CliEmail": "bgradin@terra.com.br",
    "telefone": "(11) 9 6400-7808",
    "cpf": "316.183.245-00",
    "identificador": 3078786,
    "dataHora": "17:46",
    "nomeEspetaculo": "O Homem Primitivo",
    "compraAprovada": false,
    "aceitaVoucherManual": 1,
    "dataSessao": "01/08",
    "horaSessao": "21:00"
  },
  {
    "CliNome": "Mirley Regina Barboza ",
    "CliEmail": "millam16@hotmail.com",
    "telefone": "(11) 9 8760-1378",
    "cpf": "215.851.528-70",
    "identificador": "K6XTS6",
    "dataHora": "17:45",
    "nomeEspetaculo": "Trair e Coçar é Só Começar",
    "compraAprovada": true,
    "aceitaVoucherManual": 1,
    "dataSessao": "28/07",
    "horaSessao": "20:00"
  },
  {
    "CliNome": "Helenita França",
    "CliEmail": "helenita.assaf@gmail.com",
    "telefone": "(12) 9 9143-7859",
    "cpf": "001.262.398-94",
    "identificador": 3078784,
    "dataHora": "17:45",
    "nomeEspetaculo": "O Que Terá Acontecido a Nayara Glória?",
    "compraAprovada": false,
    "aceitaVoucherManual": 1,
    "dataSessao": "13/08",
    "horaSessao": "20:00"
  },
  {
    "CliNome": "Lívia Bassi do Amaral Silva",
    "CliEmail": "bassilivia@gmail.com",
    "telefone": "(11) 9 4600-8682",
    "cpf": "343.157.258-89",
    "identificador": "VY92D3",
    "dataHora": "17:36",
    "nomeEspetaculo": "Música do Cinema: Orquestra Filarmônica de Santo Amaro",
    "compraAprovada": true,
    "aceitaVoucherManual": 1,
    "dataSessao": "04/08",
    "horaSessao": "20:00"
  },
  {
    "CliNome": "Michele Silva",
    "CliEmail": "michelle.angeloo@hotmail.com",
    "telefone": "(11) 9 9879-2462",
    "cpf": "356.690.878-95",
    "identificador": "JMKPPU",
    "dataHora": "17:35",
    "nomeEspetaculo": "E Aí, Sumida? Fred Elboni",
    "compraAprovada": true,
    "aceitaVoucherManual": 1,
    "dataSessao": "28/07",
    "horaSessao": "20:00"
  },
  {
    "CliNome": "Alberto Coppola Bove ",
    "CliEmail": "above@uol.com.br",
    "telefone": "(11) 9 8266-9997",
    "cpf": "166.255.498-27",
    "identificador": "KENQMM",
    "dataHora": "17:34",
    "nomeEspetaculo": "Ex Bom é Exumado",
    "compraAprovada": true,
    "aceitaVoucherManual": 1,
    "dataSessao": "28/07",
    "horaSessao": "19:30"
  },
  {
    "CliNome": "Ana Márcia Vasconcelos",
    "CliEmail": "lidianerodrigues@outlook.com",
    "telefone": "(11) 9 8932-0505",
    "cpf": "237.877.165-72",
    "identificador": "95QJV5",
    "dataHora": "17:31",
    "nomeEspetaculo": "Casa, depois me conta...",
    "compraAprovada": true,
    "aceitaVoucherManual": 1,
    "dataSessao": "28/07",
    "horaSessao": "19:30"
  },
  {
    "CliNome": "Lucy Mara Baleixo da Silva ",
    "CliEmail": "lbaleixo@yahoo.com.br",
    "telefone": "(91) 9 8850-1504",
    "cpf": "664.677.642-34",
    "identificador": "T9AKKQ",
    "dataHora": "17:26",
    "nomeEspetaculo": "Trair e Coçar é Só Começar",
    "compraAprovada": true,
    "aceitaVoucherManual": 1,
    "dataSessao": "28/07",
    "horaSessao": "20:00"
  }
]

    }

    // componentDidMount() {
    //   axios.get(`/api/menu/processosDeCompra/data/1`)
    //     .then(res => {
    //       const processosDeCompra = res.data;
    //       this.setState({ processosDeCompra });
    //       console.log(processosDeCompra);
    //     })
    // }

    render() {

        const arrProcessosDeCompra = this.state.processosDeCompra.map(processoDeCompra =>
          <ProcessoDeCompra processoDeCompra={processoDeCompra} />
         );






        return (
          <MenuMain>
            <div className={style.topBar}>
              <h3>Lista Processos de Compra</h3>
              <hr />
              <Form>
                <FormGroup row>
                  <Label for="exampleEmail" sm={2}>Selecione a data:</Label>
                  <Col sm={6}>
                  <Input type="select" name="select" id="exampleSelect">
                    <option >Hoje</option>
                    <option>Ontem (Sábado, 26/06)</option>
                    <option>Antes de ontem (Sexta, 25/06)</option>
                    <option>Quinta (24/06)</option>
                    <option>Quarta (23/06)</option>
                    <option>Terça (23/06)</option>
                    <option>Segunda (23/06)</option>
                  </Input>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="exampleEmail" sm={2}>Procurar processo:</Label>
                  <Col sm={6}>
                  <Input type="text" name="select" id="exampleSelect" placeholder="Digite o CPF, Chave, Processo ou E-mail" />
                  <p className={style.dica}>Essa opção busca todos os processos feitas nas últimas duas semanas, para uma data anterior use o <b>Consultar Compra</b></p>
                  </Col>
                  <Col sm={2}>
                  <Input type="button" className={style.submit_blue} value="Buscar" />
                  </Col>
                </FormGroup>
              </Form>
              <hr />
              <ul>
                <li><b>Legenda:</b></li>
                <li>Nomes em <span>azul</span> são clientes novos</li>
                <li>Processos em <span>verde</span> estão OK</li>
                <li>Processos em <span>amarelo</span> precisam de atenção.</li>
                <li>Processos em <span>vermelho</span> são prioritários</li>
              </ul>

            </div>
            <Table>
              <thead>
                <tr>
                  <th>Identificador</th>
                  <th>Hora</th>
                  <th>Espetáculo</th>
                  <th>Voucher Manual</th>
                  <th>Sessão</th>
                  <th>Ingressos</th>
                  <th>Cliente</th>
                  <th>E-mail</th>
                  <th>CPF</th>
                  <th>Telefone</th>
                </tr>
              </thead>
              <tbody>
                {arrProcessosDeCompra}
              </tbody>
            </Table>
          </MenuMain>
        );
    }
}

export default ListaProcessosDeCompra;
