import React, { Component } from 'react';
import axios from 'axios';
import { InputGroup, InputGroupAddon, Table, Col, Button, Form, FormGroup, Label, Input, FormText, Modal } from 'reactstrap';

import MenuMain from '../../../components/menu/MenuMain/MenuMain';
import ConsultaCompraBox from '../../../components/menu/ConsultaCompraBox/ConsultaCompraBox';

import 'bootstrap/dist/css/bootstrap.min.css';
import style from './ConsultaCompra.module.scss';

import LoadingSpinner from '../../../components/general/LoadingSpinner/LoadingSpinner';
import ErrorBox from '../../../components/general/ErrorBox/ErrorBox';

import CheckMark from '../../../images/btnCheckMark.png';
import ErroCross from '../../../images/erroCross.png';
import SetaVermelha from '../../../images/seta_vermelha.png';
import VisaLogo from '../../../images/visaLogo.jpg';

class ConsultaCompra extends Component {

    constructor(props) {
      super(props);
      this.consultarCompra = this.consultarCompra.bind(this);
      this.setarTexto = this.setarTexto.bind(this);

    }
    
    state = {
      requisitouCompra: false,
      processo: ""
    }

    consultarCompra() {
      this.setState({ requisitouCompra: true });
    }

    setarTexto(event) {
      this.setState({requisitouCompra: false, processo: event.target.value})
    }

    render() {

        return (
          <MenuMain>
            <div className={style.topBar}>
            <img src={SetaVermelha} className={style.SetaVermelha} alt='seta_volta' />
              <h3>Consulta Compra</h3>
              <Form>
                <FormGroup row>
                  <Label for="exampleEmail" sm={2}>Procurar processo:</Label>
                  <Col sm={5}>
                  <Input type="text" name="select" id="exampleSelect" placeholder="Digite a Chave ou Processo" onKeyUp={this.setarTexto}/>
                  <p className={style.dica}>Essa opção busca por chave ou processo, para usar e-mail ou CPF utilize <b>Consultar Usuário</b></p>
                  </Col>
                  <Col sm={2}>
                  <Input type="button" className={style.submit_blue} value="Buscar" onClick={this.consultarCompra}/>
                  </Col>
                </FormGroup>
              </Form>
              <hr />
              {this.state.requisitouCompra && <ConsultaCompraBox processo={this.state.processo}/>}
            </div>
          </MenuMain>
        );
    }
}

export default ConsultaCompra;
