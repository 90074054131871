import React, { Component } from 'react';
import { Row, Container, Col } from 'reactstrap';
import Logo from '../../../images/produSampa_branco.png';
import { Link } from 'react-router-dom';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import style from './LoginProdutor.module.scss';

import ErroCross from '../../../images/erroCross.png';

class LoginFuncionario extends Component {

    constructor(props) {
      super(props);
      this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
      // this.setState(state => ({
      //   isToggleOn: !state.isToggleOn
      // }));

    }

    render() {


        return (
          <Container className={style.container}>
            <Row className={style.top_bar}>
            </Row>

            <Row className={style.logo}>
              <img src={Logo} alt='LogoPreto'/>
            </Row>

            <div className={style.box}>
              <p>Produtores</p>
              <Form>
                <FormGroup>
                  <Input type="email" className={style.input} name="email" id="email" placeholder="Digite seu E-mail" />
                </FormGroup>
                <FormGroup>
                  <Input type="password" className={style.input} name="email" id="senha" placeholder="Digite sua Senha" />
                  <a href='produtor/esqueciSenha'>Esqueci Minha Senha</a>
                </FormGroup>
                <Button className={style.submit} onClick={this.handleClick}>Entrar</Button>
              </Form>
            </div>

          </Container>
        );
    }
}

export default LoginFuncionario;
