import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import style from './BoxEspetaculo.module.scss';
import LoadingSpinner from '../../../components/general/LoadingSpinner/LoadingSpinner';

import Foto from '../../../images/foto_83505.jpg';

class BoxEspetaculo extends Component {


    constructor(props) {
      super(props);
      //this.showModal = this.showModal.bind(this);
    }

    state = {
      dadosCarregados: true
    }

    componentDidMount() {

      // axios.get(`/api/menu/processosDeCompra/consultarProcesso/${this.props.processo}`)
      //   .then(res => {
      //     const data = res.data;
      //
      //     this.setState({
      //       processoDeCompra: JSON.parse(data.retorno),
      //       hasError: !(data.status),
      //       msgRetorno: data.msg,
      //       dadosCarregados: true
      //      });
      //   })
    }

    render() {
      return (
        <div className={style.boxEspetaculo}>
          <a href='#'>

            <div className={style.hooverBox}>
              <div className={style.backgroundHooverBox} />
              <div className={style.contentHooverBox}>
                <h3>A Banheira</h3>
                <hr />
                <p>O que pode acontecer quando um respeitável pai de família leva para casa uma amante absolutamente inusitada? Ou quando, pior ainda, um ladrão aparece na hora errada e a ambos prende no banheiro da residência? (Ler Mais)</p>
                <span>Compre</span>
              </div>
            </div>

            <div className={style.boxFoto}>
              <img src={Foto} />
              <div className={style.splash}><span>50%</span></div>
            </div>

            <div className={style.dadosEspetaculo}>
              <h3>A Banheira</h3>
              <hr />
              <p className={style.teatro}>Teatro Maria Della Costa</p>
              <p className={style.dataText}>Até 31 de agosto</p>
              <p className={style.hourText}>Sexta e Sábado às 21h</p>
            </div>
          </a>

        </div>
      )
    }
}

export default BoxEspetaculo;
