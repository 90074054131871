import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import style from './Footer.module.scss';

class Footer extends Component {
    render() {
        return (
          <div className={style.footer}>

          </div>
        );
    }
}

export default Footer;
