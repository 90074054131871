import React, { Component } from 'react';
import axios from 'axios';
import { InputGroup, InputGroupAddon, Table, Col, Button, Form, FormGroup, Label, Input, FormText, Modal } from 'reactstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import style from './ConsultaCompraBox.module.scss';

import LoadingSpinner from '../../../components/general/LoadingSpinner/LoadingSpinner';
import ErrorBox from '../../../components/general/ErrorBox/ErrorBox';
import ConsultaCompraBoxModal from './ConsultaCompraBoxModal/ConsultaCompraBoxModal';

import CheckMark from '../../../images/btnCheckMark.png';
import ErroCross from '../../../images/erroCross.png';
import SetaVermelha from '../../../images/seta_vermelha.png';
import VisaLogo from '../../../images/visaLogo.jpg';


class ConsultaCompraBox extends Component {


    constructor(props) {
      super(props);
      this.showModal = this.showModal.bind(this);
    }

    state = {
      dadosCarregados: true,
      processoDeCompra: {
        "retorno": "{\"idEspetaculo\":104502,\"idProcessoDeCompra\":3088087,\"chaveTransacao\":\"LPSQXG\",\"ipAddress\":\"187.75.106.156\",\"dataHora\":\"201908081820\",\"dataSessao\":\"Quinta-Feira, 19 de Setembro\",\"horaSessao\":\"21:00h\",\"setor\":null,\"qtdIngressos\":2,\"qtdIngressosAdulto\":0,\"qtdIngressosCrianca\":0,\"valorUnitario\":\"R$ 40,00\",\"valorAdulto\":null,\"valorCrianca\":null,\"qtdParcelas\":1,\"valorParcela\":\"R$ 80,00\",\"valorDoCredito\":\"R$ 0,00\",\"CliNome\":\"ALEXANDRE PIAZENTINO\",\"CliEmail\":\"piazentino@gmail.com\",\"ddd\":\"11\",\"telefone\":\"9 9729-9852\",\"cpf\":\"070.952.258-42\",\"tipoPagamento\":\"Cart\\u00e3o de Cr\\u00e9dito\",\"linkBoletoPagSeguro\":null,\"codigoDeBarrasBoleto\":null,\"espetaculo\":\"Beatles 4Ever\",\"ingressos\":[\"2 ingresso(s)\"]}",
        "status": 1,
        "msg": "dados encontrados"
      },
      hasError: false,
      msgRetorno: "",
      showModal: false,
      modalNumber: -1
    }

    componentDidMount() {

      // axios.get(`/api/menu/processosDeCompra/consultarProcesso/${this.props.processo}`)
      //   .then(res => {
      //     const data = res.data;
      //
      //     this.setState({
      //       processoDeCompra: JSON.parse(data.retorno),
      //       hasError: !(data.status),
      //       msgRetorno: data.msg,
      //       dadosCarregados: true
      //      });
      //   })
    }

    showModal(e) {
      e.preventDefault()
      const target = e.target.value
      this.setState({modalNumber: target, showModal: true})
    }

    render() {

        if(!this.state.dadosCarregados) {
          return <LoadingSpinner />
        }

        if(this.state.hasError) {
          return <ErrorBox msg={this.state.msgRetorno}/>
        }

        const processoDeCompra = JSON.parse(this.state.processoDeCompra.retorno)

        const ingressos = processoDeCompra.ingressos.map(ingresso =>
          <Input type="email" name="email" id="exampleEmail" placeholder={ingresso} disabled/>
         );

        return (
          <>
          <ConsultaCompraBoxModal idModal={this.state.modalNumber} showModal={this.state.showModal} processoDeCompra={processoDeCompra}/>
          <Form className={style.form}>
          <h3>Resultado da compra <b>{this.props.processo}</b> (Aprovada <img src={CheckMark} alt='' />)</h3>
          <hr/>
          <FormGroup row>
            <Label for="exampleEmail" sm={1}>Comprador</Label>
            <Col sm={4}>
              <Input type="email" name="email" id="exampleEmail" placeholder={processoDeCompra.CliNome} disabled />
            </Col>
            <Label for="examplePassword" sm={1}>Telefone</Label>
            <Col sm={1}>
              <Input type="password" name="password" id="examplePassword" placeholder={processoDeCompra.ddd} disabled/>
            </Col>
            <Col sm={5}>
              <Input type="password" name="password" id="examplePassword" placeholder={processoDeCompra.telefone} disabled/>
            </Col>

          </FormGroup>
          <FormGroup row>
            <Label for="exampleEmail" sm={1}>CPF</Label>
            <Col sm={4}>
              <Input type="email" name="email" id="exampleEmail" placeholder={processoDeCompra.cpf} disabled/>
            </Col>
            <Label for="examplePassword" sm={1}>E-mail</Label>
            <Col sm={4}>
              <Input type="password" name="password" id="examplePassword" placeholder={processoDeCompra.CliEmail} disabled/>
            </Col>
            <Col sm={2}>
              <Input type="button" name="password" id="examplePassword" value="Consultar" className={style.submit_blue}/>
            </Col>
            <Col sm={12}>
              <ul className={style.ul_opcoes}>
                <li><button value="MODAL_EMAIL_INVALIDO" onClick={this.showModal}>Assinalar E-mail inválido</button></li>
                <li><button value="MODAL_CORRIGIR_COMPRADOR" onClick={this.showModal}>Corrigir dados comprador</button></li>
                <li><button value="MODAL_BLOQUEAR_COMPRADOR" onClick={this.showModal} className={style.blockBtn}>Bloquear comprador</button></li>
                <li><button value="MODAL_CONTESTAR_COMPRA" onClick={this.showModal} className={style.blockBtn}>Registrar Constestação</button></li>
                <li><button value="MODAL_CANCELAR_COMPRA" onClick={this.showModal} className={style.blockBtn}>Cancelar Compra</button></li>
              </ul>
            </Col>
          </FormGroup>
          <hr />
          <FormGroup row>
          <h3>Dados do <b>voucher</b></h3>
            <Label for="exampleEmail" sm={1}>Espetáculo</Label>
            <Col sm={5}>
              <a href={"https://www.sampaonline.com.br/cultura/espetaculo.php?id="+processoDeCompra.idEspetaculo} target='_blank' className={style.espetaculo}>{processoDeCompra.espetaculo}</a>
            </Col>
            <Label for="examplePassword" sm={1}>Sessão</Label>
            <Col sm={5}>
              <div className={style.box_data}>
                <span>{processoDeCompra.dataSessao}</span>
                <span>{processoDeCompra.horaSessao}</span>
              </div>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="exampleEmail" sm={3}>Quantidade de Ingressos</Label>
            <Col sm={3}>
              {ingressos}
            </Col>
            <Col sm={2}>
              Aceita Voucher Manual
            </Col>
            <Col sm={3}>
              <img className={style.imgVoucher} src={CheckMark} />
            </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="exampleEmail" sm={3}>Nome Voucher</Label>
              <Col sm={3}>
                <Input type="email" name="email" id="exampleEmail" placeholder={processoDeCompra.CliNome} disabled />
              </Col>
              <Label for="examplePassword" sm={1}>E-mail Voucher</Label>
              <Col sm={4}>
                <Input type="password" name="password" id="examplePassword" placeholder={processoDeCompra.CliEmail} disabled/>
              </Col>
            </FormGroup>

            <FormGroup className={style.form_margin_top}>
                <ul className={style.ul_log}>
                  <li><img src={CheckMark} /></li>
                  <li>Ingresso gerado em: 07/08/2019 às 16:08</li>
                </ul>
                <ul className={style.ul_log}>
                  <li><img src={ErroCross} /></li>
                  <li className={style.error}>Voucher não emitido</li>
                </ul>
                <ul className={style.ul_log}>
                  <li><img src={CheckMark} /></li>
                  <li>Voucher já trocado por <b>Krisleen</b> em: 07/08/2019 às 16:08</li>
                </ul>
                <ul className={style.ul_log}>
                  <li><img src={CheckMark} /></li>
                  <li>Voucher já queimado pela <b>produção</b> em: 07/08/2019 às 16:08</li>
                </ul>
              <Col sm={12}>
              <ul className={style.ul_opcoes}>
                <li><span>Enviar voucher</span></li>
                <li><span>Trocar data da sessão</span></li>
                <li><span>Trocar quantidade de ingressos</span></li>
                <li><span>Trocar portador voucher</span></li>
              </ul>
              </Col>
              <Col sm={12}>
              </Col>
            </FormGroup>
          <hr />
          <FormGroup row>
          <h3>Dados da <b>compra</b></h3>
            <Col sm={12}>
            <ul className={style.ul_log_compra}>
              <li>
                <span>Hora inicio compra</span>
                <span>07/08/2019 às 16h06</span>
              </li>
              <li>
                <span>IP Adress</span>
                <span>200.205.21.18 (200-205-21-18.customer.tdatabrasil.net.br)</span>
              </li>
              <li>
                <span>idTransação</span>
                <span>12301230KWOSK12312OKSWM9878</span>
              </li>
              <li>
                <span>Meio de Pagamento</span>
                <span><img src={VisaLogo} className={style.imgPagamento} />Visa</span>
              </li>
              <li>
                <span>Valor Unitário</span>
                <span>R$ 36,00<br/>R$ 22,00</span>
              </li>
              <li>
                <span>Total da transação</span>
                <span>R$ 72,00</span>
              </li>
              <li>
                <span>Parcelamento</span>
                <span>5x de R$ 12,00</span>
              </li>
            </ul>
            <ul className={style.ul_opcoes}>
              <li><span>Consultar Transação</span></li>
              <li><span>Consultar IP</span></li>
            </ul>

            </Col>

          </FormGroup>
          </Form>
          </>
        );
    }
}

export default ConsultaCompraBox;
